@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?k3biad') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?k3biad') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?k3biad') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?k3biad##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="marso-icon-"], [class*=" marso-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.marso-icon-x-twitter {
  &:before {
    content: $marso-icon-x-twitter; 
  }
}
.marso-icon-spare-wheel {
  &:before {
    content: $marso-icon-spare-wheel; 
  }
}
.marso-icon-file-signature-solid {
  &:before {
    content: $marso-icon-file-signature-solid; 
  }
}
.marso-icon-screw-nut-3d {
  &:before {
    content: $marso-icon-screw-nut-3d; 
  }
}
.marso-icon-wheel-3d {
  &:before {
    content: $marso-icon-wheel-3d; 
  }
}
.marso-icon-DOT {
  &:before {
    content: $marso-icon-DOT;
  }
}
.marso-icon-retread {
  &:before {
    content: $marso-icon-retread;
  }
}
.marso-icon-wheel_service {
  &:before {
    content: $marso-icon-wheel_service;
  }
}
.marso-icon-sq_right {
  &:before {
    content: $marso-icon-sq_right;
  }
}
.marso-icon-sq_wrong {
  &:before {
    content: $marso-icon-sq_wrong;
  }
}
.marso-icon-minus {
  &:before {
    content: $marso-icon-minus;
  }
}
.marso-icon-plus1 {
  &:before {
    content: $marso-icon-plus1;
  }
}
.marso-icon-right {
  &:before {
    content: $marso-icon-right;
  }
}
.marso-icon-wrong {
  &:before {
    content: $marso-icon-wrong;
  }
}
.marso-icon-coll {
  &:before {
    content: $marso-icon-coll;
  }
}
.marso-icon-tomlo_nelkul {
  &:before {
    content: $marso-icon-tomlo_nelkul;
  }
}
.marso-icon-forklift {
  &:before {
    content: $marso-icon-forklift;
  }
}
.marso-icon-kart02 {
  &:before {
    content: $marso-icon-kart02;
  }
}
.marso-icon-all_season {
  &:before {
    content: $marso-icon-all_season;
  }
}
.marso-icon-filled_truck {
  &:before {
    content: $marso-icon-filled_truck;
  }
}
.marso-icon-notification {
  &:before {
    content: $marso-icon-notification;
  }
}
.marso-icon-summer {
  &:before {
    content: $marso-icon-summer;
  }
}
.marso-icon-winter {
  &:before {
    content: $marso-icon-winter;
  }
}
.marso-icon-SHOP-kosr {
  &:before {
    content: $marso-icon-SHOP-kosr;
  }
}
.marso-icon-kle_white {
  &:before {
    content: $marso-icon-kle_white;
    color: #fff;
  }
}
.marso-icon-rim-fringe-protector {
  &:before {
    content: $marso-icon-rim-fringe-protector;
  }
}
.marso-icon-account {
  &:before {
    content: $marso-icon-account;
  }
}
.marso-icon-address {
  &:before {
    content: $marso-icon-address;
  }
}
.marso-icon-agri {
  &:before {
    content: $marso-icon-agri;
  }
}
.marso-icon-caffee {
  &:before {
    content: $marso-icon-caffee;
  }
}
.marso-icon-car {
  &:before {
    content: $marso-icon-car;
  }
}
.marso-icon-car_service {
  &:before {
    content: $marso-icon-car_service;
  }
}
.marso-icon-cargo {
  &:before {
    content: $marso-icon-cargo;
  }
}
.marso-icon-cargo21 {
  &:before {
    content: $marso-icon-cargo21;
  }
}
.marso-icon-cargo2 {
  &:before {
    content: $marso-icon-cargo2;
  }
}
.marso-icon-cetelem_01 {
  &:before {
    content: $marso-icon-cetelem_01;
  }
}
.marso-icon-cetelem_02 {
  &:before {
    content: $marso-icon-cetelem_02;
  }
}
.marso-icon-clock {
  &:before {
    content: $marso-icon-clock;
  }
}
.marso-icon-creditcard {
  &:before {
    content: $marso-icon-creditcard;
  }
}
.marso-icon-customer_Service {
  &:before {
    content: $marso-icon-customer_Service;
  }
}
.marso-icon-delivery {
  &:before {
    content: $marso-icon-delivery;
  }
}
.marso-icon-discount {
  &:before {
    content: $marso-icon-discount;
  }
}
.marso-icon-download {
  &:before {
    content: $marso-icon-download;
  }
}
.marso-icon-dump {
  &:before {
    content: $marso-icon-dump;
  }
}
.marso-icon-energy_label {
  &:before {
    content: $marso-icon-energy_label;
  }
}
.marso-icon-expert {
  &:before {
    content: $marso-icon-expert;
  }
}
.marso-icon-facebook {
  &:before {
    content: $marso-icon-facebook;
  }
}
.marso-icon-fuel_label {
  &:before {
    content: $marso-icon-fuel_label;
  }
}
.marso-icon-gift {
  &:before {
    content: $marso-icon-gift;
  }
}
.marso-icon-hamburger_menu {
  &:before {
    content: $marso-icon-hamburger_menu;
  }
}
.marso-icon-hubcaps .path1 {
  &:before {
    content: $marso-icon-hubcaps-path1;
    color: rgb(247, 247, 247);
  }
}
.marso-icon-hubcaps .path2 {
  &:before {
    content: $marso-icon-hubcaps-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path3 {
  &:before {
    content: $marso-icon-hubcaps-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path4 {
  &:before {
    content: $marso-icon-hubcaps-path4;
    margin-left: -1em;
    color: rgb(51, 51, 51);
  }
}
.marso-icon-hubcaps .path5 {
  &:before {
    content: $marso-icon-hubcaps-path5;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path6 {
  &:before {
    content: $marso-icon-hubcaps-path6;
    margin-left: -1em;
    color: rgb(51, 51, 51);
  }
}
.marso-icon-hubcaps .path7 {
  &:before {
    content: $marso-icon-hubcaps-path7;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path8 {
  &:before {
    content: $marso-icon-hubcaps-path8;
    margin-left: -1em;
    color: rgb(51, 51, 51);
  }
}
.marso-icon-hubcaps .path9 {
  &:before {
    content: $marso-icon-hubcaps-path9;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path10 {
  &:before {
    content: $marso-icon-hubcaps-path10;
    margin-left: -1em;
    color: rgb(51, 51, 51);
  }
}
.marso-icon-hubcaps .path11 {
  &:before {
    content: $marso-icon-hubcaps-path11;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path12 {
  &:before {
    content: $marso-icon-hubcaps-path12;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-hubcaps .path13 {
  &:before {
    content: $marso-icon-hubcaps-path13;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path14 {
  &:before {
    content: $marso-icon-hubcaps-path14;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-hubcaps .path15 {
  &:before {
    content: $marso-icon-hubcaps-path15;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path16 {
  &:before {
    content: $marso-icon-hubcaps-path16;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-hubcaps .path17 {
  &:before {
    content: $marso-icon-hubcaps-path17;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path18 {
  &:before {
    content: $marso-icon-hubcaps-path18;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-hubcaps .path19 {
  &:before {
    content: $marso-icon-hubcaps-path19;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path20 {
  &:before {
    content: $marso-icon-hubcaps-path20;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-hubcaps .path21 {
  &:before {
    content: $marso-icon-hubcaps-path21;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path22 {
  &:before {
    content: $marso-icon-hubcaps-path22;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-hubcaps .path23 {
  &:before {
    content: $marso-icon-hubcaps-path23;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path24 {
  &:before {
    content: $marso-icon-hubcaps-path24;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-hubcaps .path25 {
  &:before {
    content: $marso-icon-hubcaps-path25;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.marso-icon-hubcaps .path26 {
  &:before {
    content: $marso-icon-hubcaps-path26;
    margin-left: -1em;
    color: rgb(230, 230, 230);
  }
}
.marso-icon-info {
  &:before {
    content: $marso-icon-info;
  }
}
.marso-icon-location {
  &:before {
    content: $marso-icon-location;
  }
}
.marso-icon-mail {
  &:before {
    content: $marso-icon-mail;
  }
}
.marso-icon-money {
  &:before {
    content: $marso-icon-money;
  }
}
.marso-icon-money_transfer {
  &:before {
    content: $marso-icon-money_transfer;
  }
}
.marso-icon-moto {
  &:before {
    content: $marso-icon-moto;
  }
}
.marso-icon-news {
  &:before {
    content: $marso-icon-news;
  }
}
.marso-icon-noise_label {
  &:before {
    content: $marso-icon-noise_label;
  }
}
.marso-icon-on_map {
  &:before {
    content: $marso-icon-on_map;
  }
}
.marso-icon-open {
  &:before {
    content: $marso-icon-open;
  }
}
.marso-icon-package {
  &:before {
    content: $marso-icon-package;
  }
}
.marso-icon-phone {
  &:before {
    content: $marso-icon-phone;
  }
}
.marso-icon-promotion_gift {
  &:before {
    content: $marso-icon-promotion_gift;
  }
}
.marso-icon-promotions {
  &:before {
    content: $marso-icon-promotions;
  }
}
.marso-icon-psg {
  &:before {
    content: $marso-icon-psg;
  }
}
.marso-icon-psg1 {
  &:before {
    content: $marso-icon-psg1;
  }
}
.marso-icon-pumpkin {
  &:before {
    content: $marso-icon-pumpkin;
  }
}
.marso-icon-rain_label {
  &:before {
    content: $marso-icon-rain_label;
  }
}
.marso-icon-refresh {
  &:before {
    content: $marso-icon-refresh;
  }
}
.marso-icon-reinforced {
  &:before {
    content: $marso-icon-reinforced;
  }
}
.marso-icon-runflat {
  &:before {
    content: $marso-icon-runflat;
  }
}
.marso-icon-search {
  &:before {
    content: $marso-icon-search;
  }
}
.marso-icon-service {
  &:before {
    content: $marso-icon-service;
  }
}
.marso-icon-shopping_cart {
  &:before {
    content: $marso-icon-shopping_cart;
  }
}
.marso-icon-susp .path1 {
  &:before {
    content: $marso-icon-susp-path1;
  }
}
.marso-icon-susp .path2 {
  &:before {
    content: $marso-icon-susp-path2;
    margin-left: -1em;
  }
}
.marso-icon-susp .path3 {
  &:before {
    content: $marso-icon-susp-path3;
    margin-left: -1em;
  }
}
.marso-icon-susp .path4 {
  &:before {
    content: $marso-icon-susp-path4;
    margin-left: -1em;
  }
}
.marso-icon-susp .path5 {
  &:before {
    content: $marso-icon-susp-path5;
    margin-left: -1em;
  }
}
.marso-icon-SUV {
  &:before {
    content: $marso-icon-SUV;
  }
}
.marso-icon-SUV01 {
  &:before {
    content: $marso-icon-SUV01;
  }
}
.marso-icon-suv2 {
  &:before {
    content: $marso-icon-suv2;
  }
}
.marso-icon-test_winner {
  &:before {
    content: $marso-icon-test_winner;
  }
}
.marso-icon-timer {
  &:before {
    content: $marso-icon-timer;
  }
}
.marso-icon-toy {
  &:before {
    content: $marso-icon-toy;
  }
}
.marso-icon-trash {
  &:before {
    content: $marso-icon-trash;
  }
}
.marso-icon-truck {
  &:before {
    content: $marso-icon-truck;
  }
}
.marso-icon-tyre {
  &:before {
    content: $marso-icon-tyre;
  }
}
.marso-icon-tyre_date {
  &:before {
    content: $marso-icon-tyre_date;
  }
}
.marso-icon-tyre_insurance {
  &:before {
    content: $marso-icon-tyre_insurance;
  }
}
.marso-icon-tyre_safe {
  &:before {
    content: $marso-icon-tyre_safe;
  }
}
.marso-icon-tyre_service {
  &:before {
    content: $marso-icon-tyre_service;
  }
}
.marso-icon-tyrestack {
  &:before {
    content: $marso-icon-tyrestack;
  }
}
.marso-icon-UHP {
  &:before {
    content: $marso-icon-UHP;
  }
}
.marso-icon-wheel {
  &:before {
    content: $marso-icon-wheel;
  }
}
.marso-icon-wifi {
  &:before {
    content: $marso-icon-wifi;
  }
}
.marso-icon-document-edit {
  &:before {
    content: $marso-icon-document-edit; 
  }
}
.marso-icon-flickr {
  &:before {
    content: $marso-icon-flickr; 
  }
}
.marso-icon-folder-upload {
  &:before {
    content: $marso-icon-folder-upload; 
  }
}
.marso-icon-files {
  &:before {
    content: $marso-icon-files; 
  }
}
.marso-icon-eye {
  &:before {
    content: $marso-icon-eye; 
  }
}
.marso-icon-camera {
  &:before {
    content: $marso-icon-camera; 
  }
}
.marso-icon-plus {
  &:before {
    content: $marso-icon-plus;
  }
}
.marso-icon-search1 {
  &:before {
    content: $marso-icon-search1;
  }
}
.marso-icon-user {
  &:before {
    content: $marso-icon-user;
  }
}
.marso-icon-check {
  &:before {
    content: $marso-icon-check;
  }
}
.marso-icon-close {
  &:before {
    content: $marso-icon-close;
  }
}
.marso-icon-remove {
  &:before {
    content: $marso-icon-remove;
  }
}
.marso-icon-times {
  &:before {
    content: $marso-icon-times;
  }
}
.marso-icon-lock {
  &:before {
    content: $marso-icon-lock;
  }
}
.marso-icon-pencil {
  &:before {
    content: $marso-icon-pencil; 
  }
}
.marso-icon-chevron-left {
  &:before {
    content: $marso-icon-chevron-left;
  }
}
.marso-icon-chevron-right {
  &:before {
    content: $marso-icon-chevron-right;
  }
}
.marso-icon-plus-circle {
  &:before {
    content: $marso-icon-plus-circle;
  }
}
.marso-icon-minus-circle {
  &:before {
    content: $marso-icon-minus-circle;
  }
}
.marso-icon-times-circle {
  &:before {
    content: $marso-icon-times-circle;
  }
}
.marso-icon-check-circle {
  &:before {
    content: $marso-icon-check-circle;
  }
}
.marso-icon-info-circle {
  &:before {
    content: $marso-icon-info-circle;
  }
}
.marso-icon-exclamation-circle {
  &:before {
    content: $marso-icon-exclamation-circle; 
  }
}
.marso-icon-exclamation-triangle {
  &:before {
    content: $marso-icon-exclamation-triangle;
  }
}
.marso-icon-warning {
  &:before {
    content: $marso-icon-warning;
  }
}
.marso-icon-chevron-up {
  &:before {
    content: $marso-icon-chevron-up;
  }
}
.marso-icon-chevron-down {
  &:before {
    content: $marso-icon-chevron-down;
  }
}
.marso-icon-upload {
  &:before {
    content: $marso-icon-upload; 
  }
}
.marso-icon-square-o {
  &:before {
    content: $marso-icon-square-o;
  }
}
.marso-icon-twitter {
  &:before {
    content: $marso-icon-twitter;
  }
}
.marso-icon-square {
  &:before {
    content: $marso-icon-square;
  }
}
.marso-icon-caret-down {
  &:before {
    content: $marso-icon-caret-down;
  }
}
.marso-icon-caret-up {
  &:before {
    content: $marso-icon-caret-up;
  }
}
.marso-icon-caret-left {
  &:before {
    content: $marso-icon-caret-left;
  }
}
.marso-icon-caret-right {
  &:before {
    content: $marso-icon-caret-right;
  }
}
.marso-icon-sort {
  &:before {
    content: $marso-icon-sort; 
  }
}
.marso-icon-unsorted {
  &:before {
    content: $marso-icon-unsorted; 
  }
}
.marso-icon-sort-desc {
  &:before {
    content: $marso-icon-sort-desc; 
  }
}
.marso-icon-sort-down {
  &:before {
    content: $marso-icon-sort-down; 
  }
}
.marso-icon-sort-asc {
  &:before {
    content: $marso-icon-sort-asc; 
  }
}
.marso-icon-sort-up {
  &:before {
    content: $marso-icon-sort-up; 
  }
}
.marso-icon-envelope {
  &:before {
    content: $marso-icon-envelope; 
  }
}
.marso-icon-angle-down {
  &:before {
    content: $marso-icon-angle-down; 
  }
}
.marso-icon-circle-o {
  &:before {
    content: $marso-icon-circle-o; 
  }
}
.marso-icon-circle {
  &:before {
    content: $marso-icon-circle;
  }
}
.marso-icon-file-text {
  &:before {
    content: $marso-icon-file-text; 
  }
}
.marso-icon-youtube {
  &:before {
    content: $marso-icon-youtube;
  }
}
.marso-icon-youtube-play {
  &:before {
    content: $marso-icon-youtube-play;
  }
}
.marso-icon-instagram {
  &:before {
    content: $marso-icon-instagram; 
  }
}
.marso-icon-recycle {
  &:before {
    content: $marso-icon-recycle; 
  }
}


;@import "sass-embedded-legacy-load-done:2";