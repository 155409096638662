<template>
    <div
        v-if="activeStopPoint"
        class="flex flex-col gap-5 max-w-96"
    >
        <div class="text-center text-2xl font-bold px-4 sm:text-left">
            {{ trans('warehouse', 'Warehouse', 'shipment') }}
        </div>

        <!-- Megálló információk. -->
        <div class="flex justify-between items-center bg-gray-darker text-white font-bold text-center px-4">
            <i class="marso-icon-forklift float-left text-3xl" />
            <span class="text-lg md:text-xl p-4">{{ activeStopPoint.deliveryAddress }}</span>
            <span class="float-right">
                <span class="text-2xl pr-1">
                    {{ shipmentStore.orderedStopPoints.length - activeStopPoint.sequenceNumber }}
                </span>
                <i class="marso-icon-chevron-right text-xl" />
            </span>
        </div>

        <!-- A megállóhoz tartozó szállítólevelek. -->
        <div
            v-for="(packingSlips, deliveryName) in groupedPackingSlips"
            :key="deliveryName"
            class="flex flex-col bg-white"
        >
            <div
                class="flex items-center justify-between p-4 cursor-pointer"
                :class="openedGroup === deliveryName ? 'border-b-3' : ''"
                @click="toggleGroup(deliveryName)"
            >
                <div class="flex flex-col justify-center gap-2">
                    <div class="font-bold text-lg">
                        {{ deliveryName }}
                    </div>
                    <div class="flex flex-col gap-2 md:flex-row md:gap-8">
                        <div
                            v-if="packingSlips[0].customerCellularPhone"
                            class="font-bold text-lg"
                        >
                            <a :href="'tel:' + packingSlips[0].customerCellularPhone">
                                <div class="flex items-center gap-2">
                                    <i class="text-green text-3xl marso-icon-phone" />
                                    <div>{{ packingSlips[0].customerCellularPhone }}</div>
                                </div>
                            </a>
                        </div>
                        <div
                            v-if="
                                packingSlips[0].customerPhone &&
                                packingSlips[0].customerCellularPhone != packingSlips[0].customerPhone
                            "
                            class="font-bold text-lg"
                        >
                            <a :href="'tel:' + packingSlips[0].customerPhone">
                                <div class="flex items-center gap-2">
                                    <i class="text-green text-3xl marso-icon-phone" />
                                    <div>{{ packingSlips[0].customerPhone }}</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <i
                    :class="[
                        'text-2xl',
                        openedGroup === deliveryName ? 'marso-icon-chevron-up' : 'marso-icon-chevron-down'
                    ]"
                />
            </div>

            <!-- Szállítólevelek(Az aktuális csoport alapján.)-->
            <transition name="fade">
                <div
                    v-if="openedGroup === deliveryName"
                >
                    <div
                        v-for="packingSlip in packingSlips"
                        :key="packingSlip.id"
                        class="border-b-3"
                    >
                        <div class="font-bold bg-gray-dark text-white px-4 py-2 text-lg">
                            {{ packingSlip.id }}
                        </div>
                        <!-- Szállítólevelekhez tartozó tételek. -->
                        <div class="items divide-y grid gap-1">
                            <div
                                v-for="item in packingSlip.items"
                                :key="item.id"
                                class="item py-2"
                            >
                                <div class="block font-bold px-4">
                                    {{ item.productName }}
                                </div>
                                <div class="grid grid-cols-2 gap-2 text-center text-xl px-4">
                                    <span>{{ item.quantity }} {{ item.salesUnit }}</span>

                                    <span
                                        v-if="'IN_WAREHOUSE' == item.storageStatus"
                                        :class="PackingSlipItemStorageStatusData.color(item.storageStatus)"
                                    >
                                        <i
                                            :class="[
                                                'text-2xl mr-2',
                                                PackingSlipItemStorageStatusData.icon(item.storageStatus)
                                            ]"
                                        />
                                        <span class="text-base font-bold">
                                            {{ PackingSlipItemStorageStatusData.label(item.storageStatus) }}
                                        </span>
                                    </span>
                                    <span
                                        v-if="'AT_CUSTOMER' == item.storageStatus"
                                        :class="PackingSlipItemStorageStatusData.color(item.storageStatus)"
                                    >
                                        <i
                                            :class="[
                                                'text-2xl mr-2',
                                                PackingSlipItemStorageStatusData.icon(item.storageStatus)
                                            ]"
                                        />
                                        <span class="text-base font-bold">
                                            {{ PackingSlipItemStorageStatusData.label(item.storageStatus) }}
                                        </span>
                                    </span>
                                    <label
                                        v-if="'ON_TRUCK' == item.storageStatus"
                                        class="custom-checkbox text-center cursor-pointer"
                                    >
                                        <input
                                            v-model="formVariables[packingSlip.id].checkedItems"
                                            type="checkbox"
                                            hidden
                                            :value="item.id"
                                            @change="resetSignaturesAndFiles();"
                                        >
                                        <i class="checked w-10 text-2xl text-gray marso-icon-square-o relative">
                                            <i class="w-10 absolute left-0 px-2 text-2xl text-green marso-icon-right px-2" />
                                        </i>
                                        <i class="not-checked w-10 px-2 text-2xl text-gray-dark marso-icon-square-o" />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-2 text-center border-t-1">
                            <span class="font-bold text-xl">
                                {{ formatPrice(packingSlip.cashOnDeliveryPrice, packingSlip.currency) }}
                            </span>
                        </div>
                    </div>
                    <div class="px-4 py-2 bg-green text-white font-bold text-center">
                        <span class="font-bold text-xl">
                            {{ trans('total_amount', 'Total amount', 'shipment') }}{{ getTotalAmount() }}
                        </span>
                    </div>

                    <!-- Név megadás, aláírás, fotózás/fájlfeltöltés. -->
                    <div
                        v-if="getNotices(packingSlips) !== ''"
                        class="p-4"
                    >
                        <span class="font-bold pr-2">
                            {{ trans('notice', 'Notice', 'packing_slip') }}: </span>{{ getNotices(packingSlips) }}
                    </div>
                    <div
                        v-if="checkPackingSlipItemsStorageStatus(packingSlips)"
                        class="px-4 pt-4 pb-2"
                    >
                        <shop-input
                            v-model="recipient"
                            :placeholder="trans('recipient', 'Recipient', 'packing_slip')"
                            border
                            @update:model-value="setRecipient()"
                        />
                    </div>
                    <div
                        v-if="checkPackingSlipItemsStorageStatus(packingSlips)"
                        class="px-4 py-2 text-center"
                    >
                        <!-- Két lehetőséget biztosító gomb. Szállítólevél aláírása, vagy feltöltése. -->
                        <DualActionButton
                            class="p-4"
                            :on-primary-action="() => primaryAction()"
                            :on-secondary-action="() => secondaryAction()"
                            :is-primary-disabled="isSignButtonDisabled"
                            :is-secondary-disabled="isPhotoButtonDisabled"
                        />
                        <!-- Fájlfeltöltés. -->
                        <MultipleFileInput
                            :key="inputKey"
                            need-base64
                            accept="image/*"
                            :show-button="false"
                            :files="files"
                        />
                    </div>
                    <div class="flex justify-center px-4 py-2">
                        <button
                            type="button"
                            class="w-full btn btn-success !h-16 !text-lg"
                            :disabled="!checkPackingSlipItemsStorageStatus(packingSlips)"
                            :class="[
                                doneButtonMessage ? 'show-popup-message': '',
                                doneButtonMessageStatus ? doneButtonMessageStatus : ''
                            ]"
                            :data-popup-message="doneButtonMessage"
                            style="width: 100%; max-width: 24rem;"
                            @click="packingSlipDone(packingSlips)"
                        >
                                {{ trans('done', 'Done', 'shipment') }}
                        </button>
                    </div>
                </div>
            </transition>

            <!-- Aláírás megadása. -->
            <signing-modal
                :is-open="isSigningModalOpen"
                @toggle="toggleSigningModal()"
                @sign="receiveSignImageData"
            />
        </div>

        <!-- Váltás a megállók közt. -->
        <div class="flex justify-between items-center gap-4 bg-white p-2">
            <button
                type="button"
                class="w-12 h-12 btn font-bold p-4"
                :title="trans('back', 'Back', 'shipment')"
                @click="moveTo(--activeStep)"
            >
                <i class="marso-icon-chevron-left" />
            </button>
            <button
                type="button"
                class="btn btn-primary font-bold p-4"
                @click="moveTo(shipmentStore.orderedStopPoints.length + 1)"
            >
               <div>{{ trans('address_list', 'Address list', 'shipment') }}</div>
            </button>
            <button
                type="button"
                class="w-12 h-12 btn font-bold p-4"
                :title="trans('next', 'Next', 'shipment')"
                @click="moveTo(++activeStep)"
            >
                <i class="marso-icon-chevron-right" />
            </button>
        </div>
    </div>

    <!-- Megálló összesítő nézet. -->
    <div
        v-if="finalPage"
        class="flex flex-col gap-4"
    >
        <div class="p-4 text-2xl font-bold uppercase">
            {{ trans('items_list', 'Items list', 'packing_slip') }}
        </div>
        <div class="flex flex-col gap-4">
            <template
                v-for="stopPoint in stopPointsSummary()"
                :key="stopPoint.id"
            >
                <div
                    class="flex border-l-8 text-lg font-bold bg-white cursor-pointer"
                    :class="[stopPoint.isDone ? 'border-green': 'border-red']"
                    @click="moveTo(stopPoint.sequenceNumber)"
                >
                    <button
                        type="button"
                        class="p-2 btn-link text-blue"
                    >
                        #{{ stopPoint.sequenceNumber }}
                    </button>
                    <div class="p-2 w-full content-center">
                        {{ stopPoint.id }}
                    </div>
                    <div
                        v-if="stopPoint.onTruckItems"
                        class="p-2 text-base w-full text-right content-center flex gap-2 items-center justify-end"
                    >
                        <span class="hidden md:inline-block">
                            {{ trans(
                                'X_items_waiting_to_be_done',
                                '{itemCount} items waiting to be done.',
                                'packing_slip', {itemCount: stopPoint.onTruckItems}
                            ) }}
                        </span>
                        <i class="marso-icon-pencil text-2xl text-blue" />
                    </div>
                </div>
            </template>
        </div>

        <!-- Szállítólevelek véglegsítése.
            Ha van még ON_TRUCK státuszú szállítólevél, akkor hibaüzenetet dobunk.
            Ha nincs, akkor véglegesítjük őket és a főoldalra kerülünk.
        -->
        <div class="flex gap-2 p-2 bg-white">
            <button
                type="button"
                class="w-12 btn font-bold p-4"
                :title="trans('back', 'Back', 'shipment')"
                @click="moveTo(activeStep-1)"
            >
                <i class="marso-icon-chevron-left" />
            </button>
            <button
                class="w-full btn btn-primary"
                type="button"
                :class="[
                    finishButtonMessage ? 'show-popup-message': '',
                    finishButtonMessageStatus ? finishButtonMessageStatus : ''
                ]"
                :data-popup-message="finishButtonMessage"
                @click="deliveryFinished"
            >
                {{ trans('delivery_finished', 'Delivery finished', 'shipment') }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useShipmentStore } from '../../stores/shipment/shipmentStore';
import { useToastStore } from '../../stores/toast/toastStore';
import { trans } from "../../common/i18n";
import { formatPrice } from "../../common/utils/shop";
import ServerError from '../../common/error/ServerError';
import { StopPointInterface } from '../../models/StopPoints';
import { PackingSlipInterface } from '../../models/PackingSlip';
import { PackingSlipParamsInterface } from '../../models/PackingSlipParams';
import { PackingSlipItemStatus } from "../../models/PackingSlipItemStatus";
import {
    PackingSlipItemStorageStatus,
    PackingSlipItemStorageStatusData
} from "../../models/PackingSlipItemStorageStatus";
import { PackingSlipStatus } from '../../models/PackingSlipStatus';
import ShopInput from "../../components/Form/ShopInput.vue";
import DualActionButton from '../../components/Form/DualActionButton.vue';
import SigningModal from "../../components/SigningModal.vue";
import MultipleFileInput from '../../components/Form/Input/MultipleFileInput.vue';
import { ShipmentInterface } from '../../models/Shipment';
import * as Sentry from "@sentry/vue";

const router = useRouter();
const toastStore = useToastStore();
const shipmentStore = useShipmentStore();
const activeStopPoint = ref<StopPointInterface | null | undefined>(null);
const activeStopPointIsReadOnly = ref<boolean>(false);
const openedGroup = ref<string>('');
const activeStep = ref<number>(1);
const finalPage = ref<boolean>(false);
const recipient = ref<string>('');
const files = ref<File[]>([]);
const signatures = ref<Record<string, any>>({});
const isSigningModalOpen = ref<boolean>(false);
const isSignButtonDisabled = ref<boolean>(false);
const isPhotoButtonDisabled = ref<boolean>(false);
const inputKey = ref<number>(1);
const doneButtonMessage = ref<string>('');
const doneButtonMessageStatus = ref<string>('');
const finishButtonMessage = ref<string>('');
const finishButtonMessageStatus = ref<string>('');
const formVariables = ref<Record<string, {
    checkedItems: number[],
    recipient: string,
    files: any[],
    sign: any
}>>({});

// Szállítólevelek csoportosítása a vevő neve szerint.
const groupedPackingSlips = computed(() => {
    if (!activeStopPoint.value) return;

    return activeStopPoint.value.packingSlips.reduce((groups: Record<string, PackingSlipInterface[]>, packingSlip) => {
        const group = packingSlip.deliveryName;

        if (!groups[group]) groups[group] = [];

        groups[group].push(packingSlip);

        return groups;
    }, {});
});

// Aktuális szállítólevelek betöltése.
const activePackingSlips = computed(() => {
    if (!activeStopPoint.value) return;
    return activeStopPoint.value.packingSlips.filter(packingSlip => packingSlip.deliveryName === openedGroup.value);
});

onMounted(async () => {
    // Megállók lekérése.
    await getStopPoints();

    // Megálló beállítása.
    showStopPoint(true);
});

// Szállítólevél adatok és változók visszaállítása és újra inicializálása.
const reloadFormVariables = (init = false) => {
    recipient.value = '';

    if (activeStopPoint.value?.packingSlips) {
        activeStopPoint.value.packingSlips.forEach((packingSlip) => {
            // Inicializáljuk a formVariables objektumot, ha még nem létezik.
            if (!formVariables.value[packingSlip.id]) {
                formVariables.value[packingSlip.id] = {
                    checkedItems: [],
                    recipient: '',
                    files: [],
                    sign: {}
                };
            }

            if (packingSlip && PackingSlipStatus.UNFINISHED === packingSlip.status) {
                if (init) {
                    formVariables.value[packingSlip.id].recipient = packingSlip.recipient;
                    formVariables.value[packingSlip.id].checkedItems = packingSlip.items
                        .filter(item => PackingSlipItemStorageStatus.ON_TRUCK == item.storageStatus)
                        .map(item => item.id)
                        .filter((id): id is number => id !== undefined);
                }
            }
        });
    }
};

// Megállók lekérése a shipment id alapján.
const getStopPoints = async () => {
    if (!shipmentStore.shipment.id) return;

    try {
        await shipmentStore.getStopPointsByShipment(router, { shipmentId: shipmentStore.shipment.id });
    } catch (error) {
        toastStore.addToast({
            message: (error as ServerError).toString(),
            icon: 'marso-icon-notification',
            type: 'danger',
        });
    }
};

// Az aktuális szállítólevél csoport lenyitására szolgál.
const toggleGroup = (groupName: string) => {
    openedGroup.value = openedGroup.value === groupName ? '' : groupName;
    reloadFormVariables(true);
    resetSignaturesAndFiles();
};

// Szállítóleveleken lévő összegek együttes megjelenítésére szolgál.
const getTotalAmount = () => {
    if (!activeStopPoint.value || !openedGroup.value) return;

    let totalAmountInfo = {
        count: 0,
        totalAmount: 0
    }

    activeStopPoint.value.packingSlips.forEach(packingSlip => {
        if (packingSlip.deliveryName === openedGroup.value) {
            totalAmountInfo.totalAmount += packingSlip.cashOnDeliveryPrice;
            totalAmountInfo.count++;
        }
    });

    return `(${totalAmountInfo.count}):
        ${formatPrice(totalAmountInfo.totalAmount, activeStopPoint.value.packingSlips[0].currency)}`;
};

// Váltás az oldalak közt.
const moveTo = (page: number) => {
    // +1 mert szükseg van egy extra oldalra a végén.
    let maxPage = shipmentStore.stopPoints.length + 1;
    finalPage.value = false;
    if (1 > page) {
        activeStep.value = 1;
        showStopPoint();
        return;
    }

    if (maxPage <= page) {
        activeStep.value = maxPage;
        finalPage.value = true;
        showStopPoint();
        return;
    }

    activeStep.value = page;
    showStopPoint();
    resetSignaturesAndFiles();
    doneButtonMessage.value = '';
    doneButtonMessageStatus.value = '';
};

// Az aktuális megálló megjelenítését biztosítja.
const showStopPoint = async (autoSelect: boolean = false) => {
    // Lapozás után az aktuális stoppoint betöltése.
    if (autoSelect) {
        // Ha csak simán újratöltjük az oldalt arra a packing slipre ugrunk ami épp következik.
        // Tehát módosítanunk kell az activeStep változót.
        const nextStopPoint = shipmentStore.orderedStopPoints.find(stopPoint => {
            return stopPoint.packingSlips.some(packingSlip => {
                return packingSlip.items.some(item => {
                    return PackingSlipItemStorageStatus.ON_TRUCK == item.storageStatus;
                });
            });
        });

        if (nextStopPoint) {
            activeStep.value = nextStopPoint.sequenceNumber;
        } else {
            finalPage.value = true;
            activeStep.value = shipmentStore.stopPoints.length + 1;
        }
    }

    // Megálló beállítása a sorrend alapján.
    activeStopPoint.value = shipmentStore.getStopPointByOrderNumber(activeStep.value);

    // Az első szállítólevél csoport lenyitása.
    const groupKeys = Object.keys(groupedPackingSlips.value || {});
    if (groupKeys.length > 0) {
        openedGroup.value = groupKeys[0];
    }

    if (activeStopPoint.value) {
        activeStopPointIsReadOnly.value = stopPointPackingSlipHasWaitingItems(activeStopPoint.value);
    }

    reloadFormVariables(true);
};

// Adott megállóhoz tartozó szállítólevél tételek ellenőrzése: van-e köztük várakozó állapotban lévő tétel.
const stopPointPackingSlipHasWaitingItems = (stopPoint: StopPointInterface): boolean => {
    return stopPoint.packingSlips.some(packingSlip => {
        return packingSlip.items.some(item => item.status === PackingSlipItemStatus.WAITING);
    });
};

// Aláírás modalhoz tartozó függvények.
const primaryAction = () => {
    toggleSigningModal();
};

const secondaryAction = () => {
    document.querySelector<HTMLInputElement>('#multiple-file-input input[type="file"]')?.click();
};

const toggleSigningModal = () => {
    isSigningModalOpen.value = !isSigningModalOpen.value;
}

const receiveSignImageData = (imageData: string) => {
    if (!imageData || !activePackingSlips.value) return;

    activePackingSlips.value.forEach((packingSlip: PackingSlipInterface) => {
        if (formVariables.value[packingSlip.id].checkedItems.length) {
            for (const checkedItemId of formVariables.value[packingSlip.id].checkedItems) {
                if (packingSlip.items.some(item => item.id === checkedItemId)) {
                    if (!signatures.value[packingSlip.id]) {
                        const dateNow = Date.now();
                        const imageName = `${packingSlip.id}-sign.png`;
                        const imageType = imageData.substring(imageData.indexOf(':') + 1, imageData.indexOf(';'));
                        const imageObject = {
                            base64: imageData,
                            lastModified: dateNow,
                            name: imageName,
                            sequence: 1,
                            size: imageData.length,
                            type: imageType,
                        };

                        // Ellenőrizzük, hogy az objektum minden kulcsa megvan-e
                        const requiredKeys = ["base64", "lastModified", "name", "sequence", "size", "type"];
                        const missingKeys = requiredKeys.filter(key => !(key in imageObject));

                        if (missingKeys.length > 0) {
                            const errorMessage = `ShipmentUnfinished - Missing required keys in image object: ${missingKeys.join(", ")}`;
                            Sentry.captureException(new Error(errorMessage));

                            return;
                        }

                        signatures.value[packingSlip.id] = imageObject;
                    }
                    break;
                }
            }
        }
    });
};

// A megállón lévő szállítóleveleket kezeljük.
const packingSlipDone = async (packingSlips: PackingSlipInterface[]) => {
    if (!packingSlips) return;

    let isValid = true;
    let packingSlipParams: Record<string, PackingSlipParamsInterface> = {};
    doneButtonMessage.value = '';
    doneButtonMessageStatus.value = '';

    // Megnézzük, hogy van-e olyan szállítólevél amire teljesül minden elvárt feltétel.
    // Ha igen, akkor tovább lépünk és betöltjük a szállítólevelek adatait.
    const isSuccess = packingSlips.some(packingSlip => {
        if (
            !formVariables.value[packingSlip.id].checkedItems.length ||
            !recipient.value ||
            (!Object.keys(formVariables.value[packingSlip.id].sign).length &&
            !formVariables.value[packingSlip.id].files.length)
        ) {
            return false;
        }
        return true;
    });

    // Ha nem, akkor a megfelelő szállítólevélnél hibaüzeneteket dobunk amikor valamelyik adat hiányzik.
    if (!isSuccess) {
        let filteredPackingSlips = packingSlips.filter(
            packingSlip => formVariables.value[packingSlip.id].checkedItems.length > 0
        );

        if (filteredPackingSlips.length === 0) {
            filteredPackingSlips = packingSlips;
        }

        for (const packingSlip of filteredPackingSlips) {
            if (!formVariables.value[packingSlip.id].checkedItems.length) {
                isValid = false;
                const errorMessage = trans(
                    'please_select_at_least_one_item',
                    'Please select at least one item!',
                    'packing_slip'
                );

                Sentry.captureException(new Error(`ShipmentUnfinished - Packing Slip ${packingSlip.id}: ${errorMessage}`));

                toastStore.addToast({
                    message: errorMessage,
                    icon: 'marso-icon-notification',
                    type: 'danger'
                });
            }

            if (!recipient.value) {
                isValid = false;
                const errorMessage = trans(
                    'please_enter_the_recient_name',
                    'Please enter the recipient name!',
                    'packing_slip'
                );

                Sentry.captureException(new Error(`ShipmentUnfinished - Packing Slip ${packingSlip.id}: ${errorMessage}`));

                toastStore.addToast({
                    message: errorMessage,
                    icon: 'marso-icon-notification',
                    type: 'danger'
                });
            }

            if (!Object.keys(formVariables.value[packingSlip.id].sign).length &&
                !formVariables.value[packingSlip.id].files.length
            ) {
                isValid = false;
                const errorMessage = trans(
                    'please_select_at_least_one_file_for_upload',
                    'Please select at least one file for upload!',
                    'packing_slip'
                );

                Sentry.captureException(new Error(`ShipmentUnfinished - Packing Slip ${packingSlip.id}: ${errorMessage}`));

                toastStore.addToast({
                    message: errorMessage,
                    icon: 'marso-icon-notification',
                    type: 'danger'
                });
            }

            if (!isValid) {
                doneButtonMessageStatus.value = 'error';
                doneButtonMessage.value += trans(
                    'error_on_form_see_the_alert_message',
                    'Error on form, see the alert message!',
                    'packing_slip'
                );

                setTimeout(() => {
                    doneButtonMessageStatus.value = '';
                    doneButtonMessage.value = '';
                }, 3000);

                return;
            }
        }
    }

    // Adatok betöltése.
    for (const packingSlip of packingSlips) {
        formVariables.value[packingSlip.id].checkedItems.forEach(checkedItemId => {
            packingSlip.items.forEach(item => {
                if (item.id === checkedItemId) {
                    packingSlipParams[packingSlip.id] = {
                        files: formVariables.value[packingSlip.id].files,
                        sign: formVariables.value[packingSlip.id].sign,
                        recipient: formVariables.value[packingSlip.id].recipient,
                        items: formVariables.value[packingSlip.id].checkedItems
                    };
                }
            });
        });
    }

    try {
        await shipmentStore.apiPackingSlipWarehouseDone(router, packingSlipParams);
        await getStopPoints();
        resetSignaturesAndFiles();

        // Ellenőrizzük, hogy van-e még olyan tétel, ami ON_TRUCK állapotban van.
        const shipmentDone = !shipmentStore.stopPoints.some(stopPoint => {
            return checkPackingSlipItemsStorageStatus(stopPoint.packingSlips);
        });

        // Ha nincs már ON_TRUCK státuszú tétel, akkor lezárjuk a szállítást.
        if (shipmentDone) {
            deliveryFinished();
        }

        showStopPoint(true);

        // Következő szállítólevél csoport lenyitása.
        if (groupedPackingSlips.value && Object.keys(groupedPackingSlips.value).length > 1) {
            for (const groupName of Object.keys(groupedPackingSlips.value)) {
                if (checkPackingSlipItemsStorageStatus(groupedPackingSlips.value[groupName]) &&
                    groupName === openedGroup.value
                ) {
                    break;
                } else if (checkPackingSlipItemsStorageStatus(groupedPackingSlips.value[groupName])) {
                    toggleGroup(groupName);
                    break;
                }
            }
        }

        toastStore.addToast({
            message: trans(
                'packing_slip_is_modified',
                'Packing slip is modified.',
                'packing_slip'
            ),
            icon: 'marso-icon-notification',
            type: 'success'
        });
    } catch (error) {
        toastStore.addToast({
            message: trans(
                'packing_slip_modify_failed',
                'Packing slip modify failed!',
                'packing_slip'
            ),
            icon: 'marso-icon-notification',
            type: 'danger'
        });
    }
};

// A paraméterben kapott szállítólevelekhez tartozó tételek ellenőrzése: van-e köztük várakozó állapotban lévő tétel.
const checkPackingSlipItemsStorageStatus = (packingSlips: PackingSlipInterface[]) => {
    if (!packingSlips) return;

    return packingSlips.some(packingSlip => {
        return packingSlip.items.some(item => item.storageStatus === PackingSlipItemStorageStatus.ON_TRUCK);
    });
};

// Tételek módosítása esetén visszaállítjuk az aláírás és fájl tömböket.
const resetSignaturesAndFiles = () => {
    if (!activePackingSlips.value) return;

    signatures.value = {};
    files.value.splice(0, files.value.length);

    activePackingSlips.value.forEach((packingSlip: PackingSlipInterface) => {
        formVariables.value[packingSlip.id].sign = {};
        formVariables.value[packingSlip.id].files = [];
    });

    inputKey.value++;
};

// Ha van a paraméterben kapott szállítóleveleken megjegyzés, akkor azokat összefűzzük egybe és úgy jelenenítjük meg.
const getNotices = (packingSlips: PackingSlipInterface[]) => {
    if (!packingSlips) return;

    return packingSlips.reduce((accumulatedNotices, packingSlip) => {
        if (packingSlip.notice) {
            return accumulatedNotices + (accumulatedNotices ? '\n' : '') + packingSlip.notice;
        }

        return accumulatedNotices;
    }, '');
};

// Vevő beállítása a kiválasztott tétel alapján.
const setRecipient = () => {
    if (!activePackingSlips.value) return;

    activePackingSlips.value.forEach((packingSlip: PackingSlipInterface) => {
        if (!formVariables.value[packingSlip.id] || !formVariables.value[packingSlip.id].checkedItems.length) return;

        formVariables.value[packingSlip.id].checkedItems.forEach(checkedItemId => {
            packingSlip.items.forEach(item => {
                if (item.id === checkedItemId) {
                    formVariables.value[packingSlip.id].recipient = recipient.value;
                }
            });
        });
    });
};

// Előállítjuk a megálló listát, melyet az utolsó oldalon jelnítünk meg.
const stopPointsSummary = (): any[] => {
    let results: {
        sequenceNumber: number,
        id: string,
        isDone: boolean,
        onTruckItems: number
    }[] = [];

    shipmentStore.orderedStopPoints.forEach(stopPoint => {
        let result = {
            sequenceNumber: stopPoint.sequenceNumber,
            id: stopPoint.deliveryAddress,
            isDone: true,
            onTruckItems: 0
        };

        let foundOnTruckItems = false;

        stopPoint.packingSlips.forEach(packingSlip => {
            let onTruckItems = packingSlip.items.filter((item) => {
                return PackingSlipItemStorageStatus.ON_TRUCK == item.storageStatus;
            });

            // Ha találunk ON_TRUCK státuszú itemet, növeljük a számlálót.
            if (onTruckItems.length > 0) {
                result.onTruckItems += onTruckItems.length;
                foundOnTruckItems = true;
            }
        });

        // Ha van ON_TRUCK státuszú item, hozzáadjuk a listához.
        if (foundOnTruckItems) {
            // Csak akkor adja hozzá, ha még nincs benne.
            if (!results.some(r => r.id === result.id && r.sequenceNumber === result.sequenceNumber)) {
                result.isDone = false;
                results.push(result);
            }
        } else {
            results.push(result);
        }
    });

    return results;
};


// Ha már nincs ON_TRUCK státuszú szállítólevél(vagyis tétel), akkor továbblépünk a főoldalra.
// Egyéb esetben hibát dobunk.
const deliveryFinished = async () => {
    if (!shipmentStore.orderedStopPoints) return;

    finishButtonMessage.value = '';
    finishButtonMessageStatus.value = '';

    for (const stopPoint of shipmentStore.orderedStopPoints) {
        if (checkPackingSlipItemsStorageStatus(stopPoint.packingSlips)) {
            finishButtonMessageStatus.value = 'error';
            finishButtonMessage.value = trans(
                'error_some_package_is_still_on_truck',
                'Error, some package is still on truck!',
                'packing_slip'
            );

            setTimeout(() => {
                finishButtonMessageStatus.value = '';
                finishButtonMessage.value = '';
            }, 3000);

            return;
        }
    }

    try {
        if (!shipmentStore.shipment.id) return;

        const response = await shipmentStore.apiShipmentDeliveryFinished(router, shipmentStore.shipment.id);

        let shipment = response as ShipmentInterface;

        shipmentStore.replaceShipment(shipment);

        toastStore.addToast({
            message: trans(
                'shipment_delivery_ended',
                'Shipment delivery ended.',
                'shipment'
            ),
            icon: 'marso-icon-notification',
            type: 'success',
            timer: 15
        });

        router.replace({ name: 'home' });
    } catch (error) {
        toastStore.addToast({
            message: trans(
                'shipment_save_failed',
                'Shipment save failed!',
                'shipment'
            ),
            icon: 'marso-icon-notification',
            type: 'danger'
        });
    }
};

// Fájlok és aláírások kezelése: beállítás, gomb ki és be kapcsolás.
watch([files, signatures], () => {
    if (!activePackingSlips.value) return;

    const allCheckedItemsEmpty = activePackingSlips.value.every(packingSlip => {
        return !formVariables.value[packingSlip.id].checkedItems.length;
    });

    // Ha nincs egyetlen tétel sem kiválasztva az adott szállítólevelekhez tartozóan, akkor mindkét gombot letiltjuk.
    if (allCheckedItemsEmpty) {
        isPhotoButtonDisabled.value = true;
        isSignButtonDisabled.value = true;
        return;
    }

    // Ha nincs aláírás vagy fájl feltöltve, akkor alaphelyzetbe állítjuk a gombokat.
    // Illetve az adott szállítólevelnek is beállítjuk a megfelelő értékeket.
    isPhotoButtonDisabled.value = files.value.length > 0;
    isSignButtonDisabled.value = Object.keys(signatures.value).length > 0;

    activePackingSlips.value.forEach((packingSlip: PackingSlipInterface) => {
        formVariables.value[packingSlip.id].files = files.value || [];
        formVariables.value[packingSlip.id].sign = signatures.value[packingSlip.id] || {};
    });
}, { deep: true });

// A tételek kiválasztása során frissítjük a szállítólevél adatokat.
watch(formVariables.value, () => {
    if (!activePackingSlips.value) return;

    activePackingSlips.value.forEach(packingSlip => {
        if (formVariables.value[packingSlip.id].checkedItems.length) {
            // Ha van kiválasztva tétel, akkor beállítjuk az adatokat.
            formVariables.value[packingSlip.id].recipient = recipient.value;
        } else {
            // Ha nincs már kiválasztva tétel, akkor visszaállítjuk az adatokat az eredeti állapotukra.
            formVariables.value[packingSlip.id].recipient = '';
        }
    });
});

// Használt tömbök és objectek visszaállítása.
onUnmounted(() => {
    shipmentStore.stopPoints = [];
    formVariables.value = {};
    signatures.value = {};
    files.value = [];
});
</script>

<style scoped>
.custom-checkbox {
    input[type="checkbox"] ~ .checked, input[type="checkbox"]:checked ~ .not-checked {
        display: none;
    }

    input[type="checkbox"]:checked ~ .checked, input[type="checkbox"] ~ .not-checked {
        display: inline-block;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}
</style>
